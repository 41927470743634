import Box from "@mui/material/Box";
import * as React from "react";
import BlogLayout from "src/components/BlogLayout";
import Paragraph from "src/components/Paragraph";
import shariImg from "src/images/community/shari.jpg";
import wendyGlassesImg from "src/images/community/wendy-glasses.jpg";
export default function RememberingWendyPage() {
  return (
    <BlogLayout slug="remembering-wendy">
      <Paragraph pt={5}>Dear Friends and Supporters of EOCP:</Paragraph>

      <Box
        component="img"
        src={wendyGlassesImg}
        sx={{ width: "40%", float: "right" }}
        ml={2}
        mb={2}
      />

      <Paragraph>
        I am writing to share some sad news. On the morning of October 25
        <sup>th</sup>, we learned that our wonderful&nbsp;Executive Director,
        Wendy Jackson, had passed away. Wendy had been ill, but her passing was
        sudden and unexpected. She was an incredible leader and cared so deeply
        for the work of EOCP and this community. As Wendy said, she had two
        children—her son Robert and EOCP. Wendy’s leadership at EOCP was
        profound and deeply impactful. We will miss her terribly.
      </Paragraph>

      <Paragraph>
        Wendy had announced in late September that she would be retiring. Before
        Wendy's passing, she and the Board of Directors had been working closely
        for several months creating a smooth transition process to ensure strong
        leadership for EOCP into the future. The succession plan included hiring
        an Interim Executive Director to give EOCP time to assess our programs
        and systems and, to conduct a thoughtful and thorough search for the
        next permanent Executive Director who will build upon the incredible
        foundations laid by Wendy.
      </Paragraph>

      <Box
        component="img"
        src={shariImg}
        sx={{ width: "25%", float: "left" }}
        mr={2}
        mb={2}
      />
      <Paragraph>
        I am also writing to let you know that as of November 1st, EOCP has an
        Interim Executive Director, Shari Wooldridge. Shari recently retired
        from her role as Executive Director of St. Vincent de Paul Society of
        San Francisco. She brings deep management experience in nonprofit
        homeless services and shelter, and extensive skills in building strong
        relationships and efficient teams.
      </Paragraph>

      <Paragraph>
        You will be hearing more from EOCP’s board and from Shari in the weeks
        and months ahead as we develop ways to celebrate Wendy’s legacy at EOCP
        and as we share stories of the many people EOCP is supporting to move
        from homelessness to permanent housing and from hopelessness to a life
        of stability.
      </Paragraph>

      <Paragraph>
        Thank you all for your support of EOCP’s clients. Along with Wendy and
        EOCP’s staff, you are what has made EOCP so impactful to so many.
      </Paragraph>

      <Paragraph>On behalf of the EOCP Board of Directors,</Paragraph>

      <Paragraph>&nbsp;</Paragraph>

      <Paragraph>
        <strong>Kevin Bremond</strong>
        <br />
        President
        <br />
        Board of Directors
      </Paragraph>
    </BlogLayout>
  );
}
